import React, { ReactElement, useMemo } from 'react';
import {
  useListReports,
  useListSprints,
  useListProducts,
  useRemoveReport,
  UserEntity,
  OrganizationParamsType,
  ProductStatus,
  ReportEntity,
  useCreateReport,
  useUpdateReport,
} from '@data';
import { daysInSprint, tabs } from '@app/constants/app';
import { useToggleState, addDays, usePrevious, fullDate, toFixedHours, monthAndDate, year, isDST } from '@app/utils';
import { useParams } from 'react-router-dom';
import { TabsType } from '@app/utils/types';
import { Trans, useTranslation } from 'react-i18next';
import InfiniteScroll from 'react-infinite-scroll-component';
import Accordion from '@app/components/core/accordion';
import { Switch } from '@app/components/core';
import cx from 'classnames';
import SprintDayReport from '@app/components/shared/sprintDayReport';
import ReportModal from '@app/components/shared/reportModal';
import RemoveReportModal from '@app/components/shared/removeReportModal';
import Typography from '@mui/material/Typography';
import { useTheme } from '@mui/material/styles';
import Grid from '@mui/material/Unstable_Grid2';
import Box from '@mui/material/Box';
import { addHours } from 'date-fns';
import SprintHeader from './SprintHeader';
import s from './styles.module.scss';

export interface SprintReportingProps {
  user: UserEntity;
}

const SprintReporting = ({ user }: SprintReportingProps): ReactElement => {
  const [hideWeekend, toggleHideWeekend] = useToggleState(true);
  const params = new URLSearchParams(window.location.search);
  const [activeTab, setActiveTab] = React.useState((params.get('tab') as TabsType) || tabs.currentSprint);
  const [productIds, setProductIds] = React.useState<string[]>([]);
  const theme = useTheme();

  const rangeForSprints = React.useMemo(
    () =>
      activeTab === tabs.currentSprint
        ? { from: new Date(), to: addDays(new Date(), daysInSprint - 1) }
        : { to: new Date() },
    [activeTab],
  );

  const { data: sprints } = useListSprints(rangeForSprints);
  const currentSprint = sprints?.items.sort(
    (a, b) => new Date(b.startDate).getTime() - new Date(a.startDate).getTime(),
  )[0];

  const { organizationId } = useParams<OrganizationParamsType>();
  const { data: products } = useListProducts(organizationId, {
    statuses: [ProductStatus.active, ProductStatus.support],
  });

  const paramsForReports = React.useMemo(
    () =>
      activeTab === tabs.currentSprint
        ? {
            from: currentSprint?.startDate,
            to: new Date(),
            productIds,
          }
        : {
            from: addHours(
              addDays(new Date(currentSprint?.endDate as string), -(daysInSprint - 1)),
              isDST(new Date()) ? -1 : 0,
            ),
            to: new Date(currentSprint?.endDate as string),
            productIds,
          },
    [activeTab, productIds, currentSprint],
  );

  const {
    data: reports,
    fetchNextPage,
    isFetching,
  } = useListReports(organizationId, user?.id as string, paramsForReports, !!sprints?.items.length, hideWeekend);

  const isFetchingPrev = usePrevious(isFetching);

  if (activeTab === TabsType.pastSprints && reports && reports?.pages?.length < 8 && isFetchingPrev && !isFetching)
    fetchNextPage();

  const { mutate: removeReport } = useRemoveReport(organizationId, user?.id as string, paramsForReports, hideWeekend);

  const { t } = useTranslation();

  const [isAddFormOpen, toggleAddForm] = useToggleState(false);
  const [isDeleteFormOpen, toggleDeleteForm] = useToggleState(false);
  const [selectedReport, setReportSelected] = React.useState<ReportEntity | null>(null);
  const [reportToEdit, setReportToEdit] = React.useState<ReportEntity | null>(null);
  const [workDayToAdd, setWorkDayToAdd] = React.useState<string | undefined>();
  const [reportProductIds, setReportProductIds] = React.useState<string[] | undefined>();

  const showEditForm = (report) => {
    setReportToEdit(report);
  };
  const closeEditForm = () => {
    setReportToEdit(null);
  };

  const showAddForm = (workDay: string, reportProductIds?: string[]) => {
    setWorkDayToAdd(workDay);

    if (reportProductIds) setReportProductIds(reportProductIds);

    toggleAddForm();
  };
  const closeAddForm = () => {
    toggleAddForm();
    setWorkDayToAdd(undefined);
  };

  const showDeleteForm = (report) => {
    setReportSelected(report);
    toggleDeleteForm();
  };
  const closeDeleteForm = () => {
    toggleDeleteForm();
    setReportSelected(null);
  };

  const handleDeleting = () => {
    if (selectedReport) {
      removeReport(selectedReport?.id);
    }
    closeDeleteForm();
  };

  const { mutate: createReport } = useCreateReport(user?.id as string, organizationId, paramsForReports, hideWeekend);

  const handleCreateReport = (values: Partial<ReportEntity>) => {
    createReport(values);
    closeAddForm();
  };

  const { mutate: updateReport } = useUpdateReport(organizationId, user?.id as string, paramsForReports, hideWeekend);

  const handleUpdateReport = (values: Partial<ReportEntity>) => {
    updateReport(values);
    closeEditForm();
  };

  const todayDate = useMemo(() => new Date(), []);

  return (
    <div className={s.page}>
      <SprintHeader
        activeTab={activeTab}
        setActiveTab={setActiveTab}
        productIds={productIds}
        setProductIds={setProductIds}
        products={products}
      />
      {/* Fix loader, hasMore is incorrect */}
      <InfiniteScroll
        dataLength={reports?.pages?.length || 0}
        next={() => fetchNextPage()}
        hasMore={activeTab !== tabs.currentSprint}
        loader={<div />}
      >
        <div className={s.pageContent}>
          {reports?.pages
            ? reports?.pages.map((page, index) => {
                const currentSprint = sprints?.items?.find(
                  (sprint) => fullDate(sprint.startDate) === page.workDaysData[page.workDaysData.length - 1].date,
                );

                const totalHours = toFixedHours(
                  page.workDaysData.reduce((pageSum, workDayTo) => pageSum + workDayTo.totalMinutes, 0) / 60,
                );

                return activeTab === tabs.pastSprints ? (
                  <Accordion
                    key={Number(index)}
                    containerClassName={s.pageContentReportWrap}
                    className={s.pageContentAccordionTitle}
                    titleContent={
                      <Grid container justifyContent="space-between">
                        <Grid container xs={12} md="auto" textAlign="start" alignItems="center">
                          <Grid xs={12} sm="auto">
                            <Typography
                              mr={2}
                              variant="h6"
                              component="span"
                              fontWeight={theme.typography.fontWeightBold}
                            >
                              {t('sprint')} {currentSprint?.number}
                            </Typography>
                          </Grid>

                          <Grid xs={12} sm="auto">
                            {currentSprint && (
                              <Typography variant="body1" component="span" color={theme.palette.grey[600]}>
                                {monthAndDate(currentSprint?.startDate)} - {monthAndDate(currentSprint?.endDate)}
                                {activeTab === tabs.pastSprints && <>, {year(currentSprint?.endDate)}</>}
                              </Typography>
                            )}
                          </Grid>
                        </Grid>

                        <Grid xs={12} md="auto" display="flex" alignItems="center">
                          <Typography>
                            <Trans
                              defaults={t('sprintTotalHours', { totalHours })}
                              components={{
                                bold: <strong />,
                              }}
                            />
                          </Typography>
                        </Grid>
                      </Grid>
                    }
                  >
                    <Box pb={3}>
                      <div className={s.pageHeaderSwitchWrapper}>
                        <Switch
                          value={!hideWeekend}
                          onChange={toggleHideWeekend}
                          text={t('titles.showWeekend')}
                          className={s.switchWrapper}
                        />
                      </div>

                      {page.workDaysData.map((item) => (
                        <SprintDayReport
                          key={item.date}
                          workDay={item}
                          todayDate={todayDate}
                          showAddForm={showAddForm}
                          showEditForm={showEditForm}
                          showDeleteForm={showDeleteForm}
                        />
                      ))}
                    </Box>
                  </Accordion>
                ) : (
                  <Box key={Number(index)} className={cx(s.pageContentOpen, s.pageContentReportWrap)}>
                    <Grid container justifyContent="space-between" mb={3}>
                      <Grid container xs={12} md="auto" textAlign="start" alignItems="center">
                        <Grid xs={12} sm="auto">
                          <Typography
                            variant="h6"
                            fontWeight={theme.typography.fontWeightBold}
                            className={s.pageContentTitle}
                          >
                            {t('sprint')} {currentSprint?.number}
                          </Typography>
                        </Grid>
                        <Grid xs={12} sm="auto">
                          {currentSprint && (
                            <Typography variant="h6" color={theme.palette.grey[600]}>
                              {monthAndDate(currentSprint?.startDate)} - {monthAndDate(currentSprint?.endDate)}
                              {activeTab === tabs.pastSprints && <>, {year(currentSprint?.endDate)}</>}
                            </Typography>
                          )}
                        </Grid>
                      </Grid>
                      <Grid xs={12} md="auto" display="flex" alignItems="center">
                        <Typography>
                          <Trans
                            defaults={t('sprintTotalHours', { totalHours })}
                            components={{
                              bold: <strong />,
                            }}
                          />
                        </Typography>
                      </Grid>
                    </Grid>

                    <div className={s.pageHeaderSwitchWrapper}>
                      <Switch
                        value={!hideWeekend}
                        onChange={toggleHideWeekend}
                        text={t('titles.showWeekend')}
                        className={s.switchWrapper}
                      />
                    </div>

                    {page.workDaysData.map((item) => (
                      <SprintDayReport
                        key={item.date}
                        workDay={item}
                        todayDate={todayDate}
                        showAddForm={showAddForm}
                        showEditForm={showEditForm}
                        showDeleteForm={showDeleteForm}
                      />
                    ))}
                  </Box>
                );
              })
            : [...new Array(10)].map((x, i) => (
                <div key={Number(i)} className={s.pageContentWorkDay}>
                  <div className={s.pageContentWorkDayHeader} />
                </div>
              ))}
        </div>
      </InfiniteScroll>

      <ReportModal
        title={t('titles.addEntry')}
        show={isAddFormOpen}
        onHide={closeAddForm}
        userId={user?.id}
        onSubmit={handleCreateReport}
        disabledDays={{ after: new Date() }}
        date={workDayToAdd}
        reportProductIds={reportProductIds}
      />

      <ReportModal
        title={t('titles.editEntry')}
        show={!!reportToEdit}
        onHide={closeEditForm}
        initialData={reportToEdit}
        userId={user?.id}
        onSubmit={handleUpdateReport}
        disabledDays={{ after: new Date() }}
      />

      <RemoveReportModal show={isDeleteFormOpen} onHide={closeDeleteForm} onRemove={handleDeleting} />
    </div>
  );
};

export default SprintReporting;
